import { dateTypeFormat } from "../../utils/utils.js";
import { getFund } from "@/api";
export default {
  data() {
    return {
      timePopShow: false,
      popType: "",
      startDate: dateTypeFormat('YYYY/mm/dd', new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)),
      endDate: dateTypeFormat('YYYY/mm/dd', new Date()),
      minDate: new Date(2023, 0, 1),
      maxDate: new Date(),
      option: [{
        "text": "资金余额",
        value: 1
      }, {
        "text": "数字币",
        value: 2
      }, {
        "text": "贡献值",
        value: 3
      }, {
        "text": "股权",
        value: 4
      }, {
        "text": "团队分红",
        value: 5
      }, {
        "text": "收益",
        value: 6
      }],
      dropValue: "",
      fundType: 1,
      pages: 1,
      num: 5,
      total: 0,
      list: []
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.$toast({
        message: '加载中...',
        type: "loading",
        forbidClick: true,
        duration: 0
      });
      getFund({
        pages: this.pages,
        num: this.num,
        start: this.startDate,
        end: this.endDate,
        type: this.dropValue
      }).then(res => {
        this.$closeToast();
        this.list = res.data;
        this.total = res.total;
      });
    },
    getTime({
      selectedValues
    }) {
      if (this.popType === 'start') {
        this.startDate = selectedValues.join('/');
      } else {
        this.endDate = selectedValues.join('/');
      }
      this.timePopShow = false;
    },
    openPop(val) {
      this.timePopShow = true;
      this.popType = val;
    }
  },
  computed: {
    pageCount() {
      return Math.ceil(this.total / this.num);
    }
  }
};