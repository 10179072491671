//时间格式化函数
// dateTypeFormat('YYYY-mm-dd', new Date())// 默认当天
// dateTypeFormat('YYYY-mm-dd HH:MM:SS', new Date())
const dateTypeFormat = function (fmt, date) {
	let ret
	const opt = {
		'Y+': date.getFullYear().toString(), // 年
		'm+': (date.getMonth() + 1).toString(), // 月
		'd+': date.getDate().toString(), // 日
		'H+': date.getHours().toString(), // 时
		'M+': date.getMinutes().toString(), // 分
		'S+': date.getSeconds().toString() // 秒
		// 有其他格式化字符需求可以继续添加，必须转化成字符串
	}
	for (const k in opt) {
		ret = new RegExp('(' + k + ')').exec(fmt)
		if (ret) {
			fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
		}
	}
	return fmt
}


// 字符串转Dom
const changeDom = function (value) {
	var objE = document.createElement("div");

	objE.innerHTML = value;

	return objE.childNodes;
}

export {
	dateTypeFormat,
	changeDom
}